
import DataTable, { Column, Row } from '@/components/DataTable/DataTable.vue'
import Button from '@/components/Form/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import Page from '@/components/Page.vue'
import { ILanguage, ITranslationEditItem } from '@/types/translations'
import { defineComponent, shallowRef } from 'vue'
import { mapGetters } from 'vuex'
import NewTranslationDialog from '@/components/Modals/Dialogs/NewTranslationDialog.vue'
import PageTop from '@/components/PageTop.vue'
import Edit from '@/components/DataTable/Edit.vue'

export default defineComponent({
  name: 'Translations',
  data() {
    return {
      showDialog: false,
      translationToEdit: undefined as ITranslationEditItem | undefined,
      NewTranslationDialog: shallowRef(NewTranslationDialog),
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'translations',
      'environment',
      'areTranslationsSyncing',
    ]),
    columns(): Column[] {
      const columns : Column[] = [
        {
          key: 'key',
          title: 'Key',
          canSort: true,
        },
      ]

      this.languages.forEach((lang: ILanguage) => {
        columns.push({
          key: lang.countryCode,
          title: `${ lang.countryCode } text`,
          canSort: true,
        })
      })

      columns.push({
        key: 'edit',
        title: '',
      })

      return columns
    },
    rows(): Row[] {
      const rows : Row[] = []

      Object.keys(this.translations).forEach((key) => {
        const row : Row = {
          key,
        }

        // Checks for each language if its set for the translation
        Object.keys(this.translations[key]).forEach((locale) => {
          const lang : ILanguage = this.languages.find((x : ILanguage) => x.locale === locale)
          row[lang.countryCode] = this.translations[key][lang.locale]
        })

        rows.push(row)
      })

      return rows
    },
  },
  methods: {
    onTranslationsSync(): void {
      this.$store.dispatch('syncAllTranslations')
    },
    onAddTranslationClick() {
      this.translationToEdit = undefined
      this.showDialog = true
    },
    onEditTranslationClick(key: string) {
      const translation = this.translations[key]

      if (!translation) {
        return
      }

      this.translationToEdit = {
        key,
        translations: Object.keys(translation).map((locale) => ({
          id: locale,
          label: this.languages.find((x: ILanguage) => x.locale === locale).countryCode,
          text: translation[locale],
        })),
      }
      this.showDialog = true
    },
  },
  components: {
    Page,
    Button,
    DataTable,
    Dialog,
    PageTop,
    Edit,
  },

})
